@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    /* Firefox */
    scrollbar-width: none;
    /* IE and Edge */
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    /* Chrome, Safari and Opera */
    display: none
}

input[type="date"] {
    color-scheme: dark;
}

/* custom css utitlity classes */
@layer components {
    .color-text {
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        --tw-gradient-to: #c084fc var(--tw-gradient-to-position);
        --tw-gradient-from: #00FFCA var(--tw-gradient-from-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
        background-image: linear-gradient(to right, var(--tw-gradient-stops));
    }

    .color-text-animated {
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        --tw-gradient-to: #c084fc var(--tw-gradient-to-position);
        --tw-gradient-from: #00FFCA var(--tw-gradient-from-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
        background-image: linear-gradient(to right, var(--tw-gradient-stops));
        background-size: 200%;
        animation: bg-animation 10s infinite alternate;
    }

    @keyframes bg-animation {
        0% {
            background-position: left;
        }

        100% {
            background-position: right;
        }
    }
}
